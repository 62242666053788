.portfolio_button{
   width: 140; 
   height: 40px;
    background: #EE9910;
     border-radius: 25px;
}
.card-title{
  color: #000000;
  padding-top: 1rem;
}

.services .icon-box {
    text-align: center;
    border: 1px solid #fff;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    background: rgb(163, 159, 159);
    border-color: rgb(163, 160, 160);
    margin-right: 15px;
    width: 100%;
    height: 250px;
    
  }
  .services .icon-box .icon {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
  }
  .services .icon-box .icon i {
    color: #070e0a;
    font-size: 36px;
    transition: 0.3s;
  }
  .services .icon-box h4 {
    font-weight: 700;
    margin-top: 80px;
    font-size: 24px;
    
  }

  .services .icon-box h4 a {
    color:#070e0a;
    transition: ease-in-out 0.3s;
    text-decoration: none;
  }
  
  .services .icon-box:hover {
    border-color: #eaecef;
  }
  .services .icon-box:hover h4 a {
    color:#070e0a;
  }

  .resume {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
   
}
  .arrowicon{
    text-align: center;
    border: 1px solid #fff;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
    border-radius:30px ;
    border-color: rgb(163, 160, 160);
    margin-right: 15px;
    width: 80%;
    height: 230px;
    
  }
  .arrowicon h4 a{
    color: rgba(0, 0, 128, 0.8);
    text-decoration: none;
    font-size: 32px;
    font-weight: 700;
  }

  .arrowicon svg{
    color: #EE9910;
  }
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-title {
    text-align: left;
    padding: 20px 0;
    position: relative;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 0;
    color: rgba(0, 0, 128, 0.8);
    position: relative;
    z-index: 2;
  } 
  
  @media (max-width: 640px) {
    .section-title h2 {
      font-size: 28px;
      margin-bottom: 15px;
    }
    .section-title span {
      font-size: 38px;
    }
  }

  @media (max-width: 450px) {
    .portfolio {
        margin-top: -15rem;
    }
}

@media (max-width: 430px) {
  .portfolio {
      margin-top: -5rem;
  }
}

@media (max-width: 390px) {
    .portfolio {
        margin-top: -4rem;
    }
}
