
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  background: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
}
.container {
  max-width:1224px;
  padding: 7rem 1.5rem 0 1.5rem;
  margin: 0 auto;
}
@font-face {
  font-family: "Poppins";
  src: url(./Font/Poppins-Medium.ttf);
}