.circle-tick {
  width: 2.5em;
  height: 2.5em;
  background-color: hsla(240, 87%, 51%, 0.726);
  border-radius: 9999px;
}

.tick {
  color: white;
  font-size: 1.25em;
}

img.about-image {
  border-radius: 1rem;
}

@media only screen and (max-width: 768px) {
 
  img.about-image {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .about-c {
    margin-top: -8rem;
  }
}

@media (max-width: 450px) {
  .about-c {
    margin-top: -8rem;
  }
}

@media (max-width: 375px) {
  .about-c {
    margin-top: -5rem;
  }
}
