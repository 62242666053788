@media only screen and (max-width: 640px) {

    h1.first-header{
        font-size: .5rem;
    }

}

@media only screen and (max-width: 1280px) {

    h1.first-header{
        font-size: 2rem;
        padding:auto;
        padding-top: 0%;
        line-height: normal;
        font-weight: 700;
    }
}


    