.slider-container {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.slides-items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transition: transform 0.3s ease-out;
}

.slide.active {
  opacity: 1;
}

.pagination-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.pag {
  background: rgb(182, 175, 175);
  padding: 0.5rem;
  border-radius: 50%;
  font-size: 0.1rem;
  padding: 0.15rem;
  transition: all 400ms ease-in-out;
}

.pag.show {
  padding: 0.32rem;
  transition: all 400ms ease-in-out;
  background: #ee9910;
}

.pagination button {
  margin: 0 5px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.pagination button.active {
  font-weight: bold;
}

.nav span {
  background: white;
  border-radius: 50%;
}

.slider-prev,
.slider-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.1rem 0.3rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-prev {
  left: 0.5rem;
}

.slider-next {
  right: 0.5rem;
}

.testimony-card {
  padding: 0;
  margin: 3rem 12rem;
}

.testimony-desc {
  padding: 1rem 1rem 2rem 3rem;
}

.testimony-img-wrapper {
  display: flex;
  padding: 1rem 0 0 0;
}

.testimony-img-wrapper div:nth-child(2) {
  padding: 0 0 0 1rem;
}
.testimony-img-wrapper div:nth-child(2) h6 {
  color: #ee9910;
}

@media screen and (max-width: 1024px) {
  .slider-container {
    height: 400px;
  }
  .testimony-card {
    padding: 2rem 0;
    margin: 2rem 1rem;
  }

  .testimony-desc {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 750px) {
  .slider-container {
    height: 450px;
  }
  .testimony-desc p {
    text-align: center;
  }
}
